import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
  },
  {
    path: 'serie/:serie',
    loadChildren: () => import('./pages/film-detail/film-detail.module').then(m => m.FilmDetailModule),
  },
  {
    path: 'serie/:serie/movie/:movie',
    loadChildren: () => import('./pages/film-detail/film-detail.module').then(m => m.FilmDetailModule),
  },
  {
    path: 'category/:category',
    loadChildren: () => import('./pages/films-list/films-list.module').then(m => m.FilmsListModule),
  },
  {
    path: 'film-detail/:filmId',
    loadChildren: () => import('./pages/film-detail/film-detail.module').then(m => m.FilmDetailModule),
  },
  {
    path: 'film-list/:category',
    loadChildren: () => import('./pages/films-list/films-list.module').then(m => m.FilmsListModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule),
  },
  {
    path: 'my-profile',
    canActivate: [AuthGuard],
    data: { roles: ['PARENT'] },
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
  },
  {
    path: 'subscription',
    canActivate: [AuthGuard],
    data: { roles: ['PARENT'] },
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: 'redeem',
    canActivate: [AuthGuard],
    data: { roles: ['PARENT'] },
    loadChildren: () => import('./pages/redeem/redeem.module').then(m => m.RedeemModule),
  },
  {
    path: 'thanks_page',
    canActivate: [AuthGuard],
    data: { roles: ['PARENT'] },
    loadChildren: () => import('./pages/payment-finish/payment-finish.module').then(m => m.PaymentFinishModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'teachers',
    loadChildren: () => import('./pages/teachers/teachers.module').then(m => m.TeachersModule),
  },
  {
    path: 'parents',
    loadChildren: () => import('./pages/parents/parents.module').then(m => m.ParentsModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('./pages/gdpr/gdpr.module').then(m => m.GdprModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
