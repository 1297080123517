import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { NotificationsModel } from '../models/notifications.model';
import { NotificationsTypeModel } from '../models/notificationsType.model';

@Injectable()
export class NotificationsService {

  private _subject = new Subject<NotificationsModel>();
  private _idx = 0;

  constructor() { }

  getObservable(): Observable<NotificationsModel> {
    return this._subject.asObservable();
  }

  info(title: string, message: string, timeout = 5000) {
    const id = this._idx++;
    const type = NotificationsTypeModel.info;
    this._subject.next({id, type, title, message, timeout});
  }

  success(title: string, message: string, timeout = 5000) {
    const id = this._idx++;
    const type = NotificationsTypeModel.success;
    this._subject.next({id, type, title, message, timeout});
  }

  warning(title: string, message: string, timeout = 10000) {
    const id = this._idx++;
    const type = NotificationsTypeModel.warning;
    this._subject.next({id, type, title, message, timeout});
  }

  error(title: string, message: string, timeout = 10000) {
    const id = this._idx++;
    const type = NotificationsTypeModel.error;
    this._subject.next({id, type, title, message, timeout});
  }

}