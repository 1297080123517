import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ProfileModel } from '../models/profile.model';
import { NotificationsService } from './notifications.service';
import { ForgotPasswordDto, ResetpassworDto } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private currentProfileSubject: BehaviorSubject<ProfileModel>;
    public currentProfile: Observable<ProfileModel>;
    private activeSubscriptionSubject: BehaviorSubject<boolean>;
    public activeSubscription: Observable<boolean>;


    constructor(
        private http: HttpClient,
        public jwtHelper: JwtHelperService,
        private notificationsService: NotificationsService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentProfileSubject = new BehaviorSubject<ProfileModel>(JSON.parse(localStorage.getItem('currentProfile')));
        this.activeSubscriptionSubject = new BehaviorSubject<boolean>(true);
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentProfile = this.currentProfileSubject.asObservable();
        this.activeSubscription = this.activeSubscriptionSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get isAuthenticated(): boolean {
        const token = this.currentUserValue?.token;

        return !this.jwtHelper.isTokenExpired(token);
    }

    public get userId(): number {
        const token = this.currentUserValue?.token || '';
        return token ? this.jwtHelper.decodeToken(token)?.id as number : null;
    }

    public get accountName(): number {
        const token = this.currentUserValue?.token;

        return token ? this.jwtHelper.decodeToken(token)?.name : null;
    }

    public get profile(): ProfileModel {
        return this.currentProfileSubject.value;
    }

    public get activeSubscritpionValue(): boolean {
        return this.activeSubscriptionSubject.value;
    }

    public get hasActiveSubscription(): boolean {
        return this.currentUserValue?.token && !!this.activeSubscritpionValue;
    }

    public set setProfile( profile: ProfileModel ) {
        localStorage.setItem('currentProfile', JSON.stringify(profile));
        this.currentProfileSubject.next(profile);
    }

    checkExpirationDate(user: any) {
        if (user?.token) {
            this.http.get<any>(`${environment.apiUrl}/videos/checkaccess/1`, {headers: { Authorization: `Bearer ${user.token}` }}).subscribe( response => {
                if( response?.expiration ) {
                    var now = new Date();
                    var expiration = new Date(response?.expiration); 
                    var expirationTime = expiration.getTime() - now.getTime();
                    var expirationDays = Math.ceil(expirationTime / (1000 * 3600 * 24));
                    this.activeSubscriptionSubject.next(expirationDays>0);   
                    if( expirationDays > 0 && expirationDays <= 5 ) {
                        this.notificationsService.warning('UWAGA', `Twoja subskrypcja wygasa za ${expirationDays} dni`);
                    }
                }
            });
        }
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}/users/login`, { email, password })
            .pipe(map(user => {
                this.checkExpirationDate(user);
                return user;
            }))
            .pipe(map( user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        if(this.accountName) {
            this.notificationsService.success('OK', `Zostałeś poprawnie wylogowany.`);
        }
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentProfile');
        this.currentUserSubject.next(null);
        this.currentProfileSubject.next(null);
        this.activeSubscriptionSubject.next(true);
    }

    forgotPassword(data: ForgotPasswordDto) {

        return this.http.get<any>(`${environment.apiUrl}/users/password_reset/${data.email}`)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);

                return user;
            }));
    }

    resetPassword(data: ResetpassworDto) {

        return this.http.post<any>(`${environment.apiUrl}/users/password_reset/${data.token}`, { password: data.password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);

                return user;
            }));
    }
}