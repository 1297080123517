import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationsModel } from 'src/app/models/notifications.model';
import { NotificationsTypeModel } from 'src/app/models/notificationsType.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Array<NotificationsModel> = [];
  private _subscription: Subscription;

  constructor(private _notificationSvc: NotificationsService) { }

  private _addNotification(notification: NotificationsModel) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: NotificationsModel) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }


  className(notification: NotificationsModel): string {

    let style: string;

    switch (notification.type) {

      case NotificationsTypeModel.success:
        style = 'success';
        break;

      case NotificationsTypeModel.warning:
        style = 'warning';
        break;

      case NotificationsTypeModel.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }

}
