import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CategoriesModel } from '../models/categories.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient) { }

  getAllCategories(): Observable<Array<CategoriesModel>> {
    return this.http.get<Array<CategoriesModel>>(`${environment.apiUrl}/categories`);
  }

  getCategory(categoryId: number): Observable<CategoriesModel> {
    return this.http.get<CategoriesModel>(`${environment.apiUrl}/categories/${categoryId}`);
  }
}
