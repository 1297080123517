// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    payementsEnabled: false,
    videoUrl: 'https://d8dsndt5n9xcj.cloudfront.net/',
    coverUrl: 'https://vodbe-promyczek-covers.s3.eu-central-1.amazonaws.com/',
    posterUrl: 'https://vodbe-promyczek-posters.s3.eu-central-1.amazonaws.com/',
    urlAddress: 'https://vod.promyczek.pl',
    apiUrl: 'https://vod.promyczek.pl', 
    gaCode: 'UA-107982423-2',
    getProperPosterUrl(url: string): string {
        return url.replace(/(https?:\/\/[^\s]+)+(\/)/ig, environment.posterUrl);
    },
    getProperCoverUrl(url: string): string {
        return url.replace(/(https?:\/\/[^\s]+)+(\/)/ig, environment.coverUrl);
    }
};
