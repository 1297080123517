import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { NotificationsService } from '../services/notifications.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private notificationService: NotificationsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch( err.status ) {
                case 401:
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.notificationService.error('Błąd logowania', 'Podane dane logowania są nieprawidłowe.');
    
                    return throwError(null);
                    break;
                case 403:
                    if( err.error?.error?.name === 'NoAccessError' ) {
                        // this.notificationService.warning('Brak dostępu do zasobu', 'Nie posiadasz dostępu do tego filmu. Sprawdź stan twojej subskrypcji');
                        return throwError('Brak dostępu do zasobu.');
                    }
                    return throwError(null);
                    break;
                case 404:
                    if( !err.url.includes('videos/checkaccess') ) {
                        // auto logout if 401 response returned from api
                        this.notificationService.error('Błąd', 'Żądany zasób nie istnieje.');
                        return throwError('Żądany zasób nie istnieje.');
                    }
                    return throwError(null);
                    break;
                default:
                    this.notificationService.error('Błąd', `Wystąpił nieznany błąd ( ${err.status} ).`);
                    const error = err.error.message || err.statusText;
                    return throwError(error);
                    break;
            }
        }))
    }
}