import { Component, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserModel } from './models/user.model';
import { CategoriesService } from './services/categories.service';
import { CategoriesModel } from './models/categories.model';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

declare let scrollFunction: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'vod-promyczek';
  currentUser: UserModel;
  isParent: number = 0;
  loggedIn: boolean = false;
  categories: Array<CategoriesModel> = [];
  pageClass: string = 'main';
  isSearch: boolean = false;

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    private authenticationService: AuthService
  ) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        this.pageClass = 'page' + event.urlAfterRedirects.replace(/\//gi, '-');
        gtag('config', environment.gaCode, 
          {
            'page_path': event.urlAfterRedirects
          }
        );
        this.isSearch = event?.url?.indexOf('search') > -1;
      }
    });
    this.authenticationService.currentUser.subscribe(x => {
      this.loggedIn = this.authenticationService.isAuthenticated;

      return this.currentUser = x;
    });
    this.authenticationService.currentProfile.subscribe(x => {
      return this.isParent = x?.isParent;
    });
    this.categoriesService.getAllCategories().subscribe( response => this.categories = response );
  }

  ngAfterViewInit() {
    window.onscroll = function() { scrollFunction() };
  }

  searchQuery(id: string) {
    // @ts-ignore
    const pattern = document.getElementById(id).value;
    if ( pattern.length > 0 ) {
      // @ts-ignore
      document.getElementById(id).value = '';
      this.router.navigate(['/search'],{ queryParams: { q: pattern}});
    }
  }

  openMobileMenu() {
    document.getElementById("mobileMenu").style.left = "0";
    document.getElementById("menuBlur").style.display = "block";
  }
  
  closeMobileMenu() {
    document.getElementById("mobileMenu").style.left = "-70vw";
    document.getElementById("menuBlur").style.display = "none";
  }

  logout() {
    this.authenticationService.logout();
    this.loggedIn = false;
    this.router.navigate(['/login']);
  }

}
